@mixin respond($breakpoint) {
    @if $breakpoint==sm-phone {
        @media(max-width: 21.25em) {
            @content
        }

        ;
        //340PX
    }

    @if $breakpoint==phone {
        @media(max-width: 37.5em) {
            @content
        }

        ;
        //600PX
    }

    @if $breakpoint==tab-port {
        @media(max-width: 56.25em) {
            @content
        }

        ;
        //900PX
    }

    @if $breakpoint==tab-land {
        @media(max-width: 75em) {
            @content
        }

        ;
        //1200PX
    }

    @if $breakpoint==big-desk {
        @media(min-width: 112.5em) {
            @content
        }

        ; //1800PX
    }
}