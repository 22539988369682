@import '../../style/abstracts/mixins';

.wrapper_404 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .four_zero_four_bg {
        h1 {
            font-size: 80px;
            color: #324A59;

            @include respond(phone) {
                font-size: 70px;
            }

        }
    }

    .contant_box_404 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
}