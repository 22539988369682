.custom_button {
    input[type='submit'] {
        padding: 10px;
        border: none;
        border-radius: 6px;
        background: #F91880;
        color: #fff;
        font-family: 'Hind Siliguri', sans-serif;
        width: 100%;
        font-size: 15px;
        width: 250px;
        cursor: pointer;

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }


}