@import './../../style/abstracts/mixins';




.home_page_content_wrapper {

    .home_enquiry_section {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 50px;

        @include respond(phone) {
            flex-direction: column-reverse;
            align-items: center;
        }

        &_form {
            width: 43%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 30px;

            a {
                text-decoration: none;
                color: #F91880;
            }

            @include respond(phone) {
                width: 90%;
                align-items: center;
            }

            flex-direction: column;

            h1 {
                font-size: 45px;
                color: #0C4A60;
                margin-bottom: 20px;
                text-transform: uppercase;

                @include respond(phone) {
                    font-size: 33px;
                }
            }

            p {
                font-size: 18px;
            }

            img {
                width: 200px;
                margin-left: -10px;
                height: 100px;
                object-fit: cover;

                @include respond(phone) {
                    width: 80%;
                }
            }


        }


        &_ad {
            width: 50%;
            display: flex;
            justify-content: center;
            padding: 20px;
            align-items: center;

            @include respond(phone) {
                width: 90%;
            }

            img {
                width: 100%;
                margin-top: -100px;
                margin-bottom: -30px;

                @include respond(phone) {
                    width: 80%;
                }
            }


        }
    }

    .home_testimonial_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        &_items {
            display: flex;
            flex-wrap: wrap;
            align-self: center;
            justify-content: space-between;
            margin-top: 20px;

            @include respond(phone) {
                justify-content: center;
            }
        }

        &_action {
            margin-top: 50px;

            input[type='submit'] {
                padding: 10px;
                border: none;
                border-radius: 6px;
                background: #F91880;
                color: #fff;
                font-family: 'Hind Siliguri', sans-serif;
                width: 100%;
                font-size: 15px;
                width: 250px;
                cursor: pointer;
            }
        }
    }


}