@import './../../style/abstracts/mixins';

.reviews_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    &-filter {}

    &-average {
        display: flex;

        .rating_status {
            margin-left: 10px;
        }
    }

    @include respond(phone) {
        flex-direction: column;
    }
}

.reviews_cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &:nth-child(3n) {
        margin-right: 0;

        @include respond(phone) {
            margin-right: 0;

        }
    }

    @include respond(phone) {
        justify-content: center;
    }
}

.reviews_action_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}