@import './../../style/abstracts/mixins';

.footer_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #0C4A60;

    p {
        color: #fff;
    }
}

.footer_content {
    width: 1120px;
    max-width: 1120px;
    padding: 0 15px;

    @include respond(phone) {}

    .logo_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;

        border-bottom: 1px solid #7F92B0;

        .logo_desk {
            @include respond(phone) {
                display: none;
            }
        }

        .logo_mob {
            display: none;

            @include respond(phone) {
                display: block;
            }
        }

        p {
            @include respond(phone) {
                display: none;
            }
        }
    }

    .actions_content {
        padding-top: 35px;
        padding-bottom: 35px;
        border-bottom: 1px solid #7F92B0;
        display: flex;
        justify-content: space-between;

        @include respond(phone) {
            flex-direction: column;
        }

        h3 {
            color: #fff;
            margin-bottom: 10px;
            text-transform: uppercase;
            font-weight: normal;
            font-size: 18px;
        }

        .action_links {
            width: 43%;
            display: flex;
            justify-content: space-between;

            @include respond(phone) {
                width: 100%;
                border-bottom: 1px solid #7F92B0;
                padding-bottom: 20px;
            }

            &_address {
                @include respond(phone) {
                    width: 62%;
                }

                &_icons {
                    display: flex;
                    margin-bottom: 10px;
                }
            }

            &_pages {
                display: flex;
                flex-direction: column;

                @include respond(phone) {
                    width: 35%;
                }

                a {
                    margin-bottom: 10px;
                }
            }
        }

        .action_stats {
            width: 48%;

            @include respond(phone) {
                width: 100%;

            }

            &_numbers {
                display: flex;
                justify-content: space-between;
                margin-bottom: 35px;


                @include respond(phone) {
                    border-bottom: 1px solid #7F92B0;
                    padding: 20px 0;
                }

                &>* {
                    @include respond(phone) {
                        width: 33.33%;
                    }
                }

                &_item {

                    p {
                        font-size: 25px;

                        @include respond(phone) {
                            font-size: 20px;
                        }
                    }

                    h3 {
                        color: #fff;
                        font-weight: normal;
                        font-size: 15px;

                        @include respond(phone) {}
                    }
                }
            }

            &_newsletter {

                h4 {
                    color: #fff;
                    font-weight: normal;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                }

                input[type='email'] {
                    padding: 10px;
                    border: none;
                    outline: none;
                    border-top-left-radius: 7px;
                    border-bottom-left-radius: 7px;
                    font-family: 'Hind Siliguri', sans-serif;
                }

                input[type='submit'] {
                    padding: 10px;
                    width: 120px;
                    background: #F91880;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    border-top-right-radius: 7px;
                    border-bottom-right-radius: 7px;
                    color: #fff;
                    font-family: 'Hind Siliguri', sans-serif;
                }
            }
        }
    }

    .copyright_content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;

        @include respond(phone) {
            flex-direction: column;
        }

        p {
            @include respond(phone) {
                margin-bottom: 10px;
            }
        }

    }
}