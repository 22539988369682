@import './../../style/abstracts/mixins';



.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.main {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #F8FBFF;
  margin-bottom: 50px;

  h3,
  h2 {
    color: #324A59;
    margin-bottom: 10px;
  }

  h2 {
    margin-top: 20px;

  }

  p {

    color: #324A59;
  }

  ul {
    margin-left: 30px;

    li {
      list-style-type: decimal;

      color: #324A59;
    }
  }
}

.main-app {
  background-color: #F8FBFF;
  padding: 20px;
  overflow: hidden;

  h3,
  h2 {
    color: #324A59;
    margin-bottom: 10px;
  }

  h2 {
    margin-top: 20px;

  }

  p {

    color: #324A59;
  }

  ul {
    margin-left: 30px;

    li {
      list-style-type: decimal;

      color: #324A59;
    }
  }
}

.main_content {
  width: 1120px;
  max-width: 1120px;
  margin-top: 20px;
  padding: 0 15px;

  @include respond(phone) {
    width: 100%;
  }


}