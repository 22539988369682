@import './../../style/abstracts/mixins';

.how_to_earn_img {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond(phone) {
        flex-direction: column;
    }

    &_left {
        width: 60%;

        h2 {
            margin: 0px;
            padding: 0px;
        }

        @include respond(phone) {
            width: 100%;
        }
    }

    &_right {
        width: 40%;
        display: flex;
        justify-content: flex-end;


        @include respond(phone) {
            width: 100%;
            justify-content: center;
            margin: 15px 0;
        }
    }


}

ul {
    li {


        a {
            color: #F91880;
        }

        img {
            width: 400px;

            @include respond(phone) {
                width: 90%;
            }

        }
    }
}

.home_testimonial_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    &_items {
        display: flex;
        flex-wrap: wrap;
        align-self: center;
        justify-content: space-between;
        margin-top: 20px;

        @include respond(phone) {
            justify-content: center;
        }
    }

    &_action {
        margin-top: 50px;

        input[type='submit'] {
            padding: 10px;
            border: none;
            border-radius: 6px;
            background: #F91880;
            color: #fff;
            font-family: 'Hind Siliguri', sans-serif;
            width: 100%;
            font-size: 15px;
            width: 250px;
            cursor: pointer;
        }
    }
}

.play_icon {
    width: 150px;
    margin-top: -50px;
    margin-left: -10px;
    margin-bottom: -80px;

    @include respond(phone) {
        width: 60%;
        margin-bottom: -100px;
    }
}